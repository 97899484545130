<script setup lang="ts">
import type { Brand } from "@/model/backend/brand"
import { terpenes } from "@/model/terpenes"
import { computed, onMounted, ref } from "vue"
import { useRoute, useRouter } from "vue-router"
import { useShopAppConfig } from "../composables"
import { useArticleFilterStore } from "../store"
import type { ArticleThcFilter } from "../store/useArticleFilterStore"

const route = useRoute()
const router = useRouter()

function attached(): void {
    redirectOnClick.value = false
    const brand = filterStore.brand
    selectType(filterStore.type ?? "cannabis")
    selectBrand(brand)
    redirectOnClick.value = route.name !== "articles"
}

function selectType(type: string): void {
    filterStore.type = type
    selectBrand(undefined)
}

function selectBrand(brandId: string | undefined): void {
    const newBrand = filterStore.brand === brandId ? undefined : brandId
    if (redirectOnClick.value) {
        if (newBrand == null && brandId != null) {
            // clicked on same as already active but we are not on the articles page -> do not update filter
        } else {
            filterStore.brand = newBrand
        }
        router.push({ name: "articles" })
        return
    }

    filterStore.brand = newBrand
}

function selectSpecies(species: string): void {
    filterStore.species = filterStore.species === species ? undefined : species

    if (redirectOnClick.value) {
        router.push({ name: "articles" })
    }
}
/*─────────────────────────────────────┐
│   composables                        │
└─────────────────────────────────────*/
const { appConfig } = useShopAppConfig()
const filterStore = useArticleFilterStore()
/*─────────────────────────────────────┐
│   refs                               │
└─────────────────────────────────────*/
const brands = computed(() => {
    const existingBrandIds: string[] = []
    const brands: Brand[] = []

    for (const article of appConfig.value.articles) {
        if (article.brand?.id && !existingBrandIds.includes(article.brand.id) && article.type === filterStore.type) {
            existingBrandIds.push(article.brand.id)
            brands.push(article.brand)
        }
    }

    brands.sort((a: Brand, b: Brand) => {
        if ((a.name ?? "") < (b.name ?? "")) {
            return -1
        }
        if ((a.name ?? "") > (b.name ?? "")) {
            return 1
        }

        return 0
    })

    return brands
})

const species = computed(() => {
    const selectedType = filterStore.type
    const selectedBrand = filterStore.brand
    const species: string[] = []

    for (const article of appConfig.value.articles) {
        if (
            !species.includes(article.species ?? "") &&
            article.type == selectedType &&
            (!selectedBrand || selectedBrand === article.brand?.id) &&
            article.species
        ) {
            species.push(article.species)
        }
    }

    return species.sort()
})

const redirectOnClick = ref(false)
const sidebar = ref<HTMLDivElement>()
/*─────────────────────────────────────┐
│   setup                              │
└─────────────────────────────────────*/
onMounted(() => attached())

/*─────────────────────────────────────┐
│   THC filter                         │
└─────────────────────────────────────*/
const thcFilters = [
    {
        name: "< 15%",
        min: 0,
        max: 15,
    },
    {
        name: "15% - 20%",
        min: 15,
        max: 20,
    },
    {
        name: "20% - 25%",
        min: 20,
        max: 25,
    },
    {
        name: "> 25%",
        min: 25,
        max: 999999,
    },
]

function selectThcFilter(filter: ArticleThcFilter): void {
    filterStore.thc = filterStore.thc?.name === filter.name ? undefined : filter

    if (redirectOnClick.value) {
        router.push({ name: "articles" })
    }
}

/*─────────────────────────────────────┐
│   price filter                       │
└─────────────────────────────────────*/
const priceFilters = [
    {
        name: "Blue",
        value: "blue",
    },
    {
        name: "Green",
        value: "green",
    },
    {
        name: "Yellow",
        value: "yellow",
    },
    {
        name: "Purple",
        value: "purple",
    },
]

function selectPriceFilter(filter: (typeof priceFilters)[number]): void {
    filterStore.priceCategory = filterStore.priceCategory === filter.value ? undefined : filter.value

    if (redirectOnClick.value) {
        router.push({ name: "articles" })
    }
}

/*─────────────────────────────────────┐
│   Terpene filter                     │
└─────────────────────────────────────*/

function selectTerpene(terpene: string): void {
    filterStore.terpene = filterStore.terpene === terpene ? undefined : terpene

    if (redirectOnClick.value) {
        router.push({ name: "articles" })
    }
}
</script>

<template>
    <div ref="sidebar" class="product-filter-sidebar">
        <div class="product-filter">
            <div class="product-filter-name">Produkte</div>
            <ul class="product-filter-items">
                <li>
                    <a :class="{ active: filterStore.type === 'cannabis' }" @click.prevent="selectType('cannabis')">
                        Blüten
                    </a>
                </li>
                <li>
                    <a :class="{ active: filterStore.type === 'essence' }" @click.prevent="selectType('essence')">
                        Extrakte
                    </a>
                </li>
                <li>
                    <a
                        v-if="appConfig.user"
                        :class="{ active: filterStore.type === 'standard' }"
                        @click.prevent="selectType('standard')"
                    >
                        Zubehör
                    </a>
                </li>
                <li>
                    <a
                        v-if="appConfig.user && appConfig.user.payType !== 'doctor'"
                        :class="{ active: filterStore.type === 'merch' }"
                        @click.prevent="selectType('merch')"
                    >
                        Merch
                    </a>
                </li>
            </ul>
        </div>
        <div class="product-filter">
            <div class="product-filter-name">Marke</div>
            <ul class="product-filter-items">
                <li>
                    <a :class="{ active: filterStore.brand === null }" @click.prevent="selectBrand(undefined)">Alle</a>
                </li>
                <li v-for="brand in brands" :key="brand.id">
                    <a :class="{ active: filterStore.brand === brand.id }" @click.prevent="selectBrand(brand.id)">
                        {{ brand.name }}
                    </a>
                </li>
            </ul>
        </div>

        <div v-if="species.length > 0" class="product-filter">
            <div class="product-filter-name">Genetik</div>
            <ul class="product-filter-items">
                <li v-for="specie in species" :key="specie">
                    <a :class="{ active: filterStore.species === specie }" @click.prevent="selectSpecies(specie)">
                        {{ specie }}
                    </a>
                </li>
            </ul>
        </div>

        <div class="product-filter">
            <div class="product-filter-name">THC Gehalt</div>
            <ul class="product-filter-items">
                <li v-for="filter in thcFilters" :key="filter.name">
                    <a
                        :class="{ active: filterStore.thc?.name === filter.name }"
                        @click.prevent="selectThcFilter(filter)"
                    >
                        {{ filter.name }}
                    </a>
                </li>
            </ul>
        </div>

        <div v-if="appConfig.showPrices" class="product-filter">
            <div class="product-filter-name">Preisgruppe</div>
            <ul class="product-filter-items">
                <li v-for="filter in priceFilters" :key="filter.name">
                    <a
                        :class="{ active: filterStore.priceCategory === filter.value }"
                        @click.prevent="selectPriceFilter(filter)"
                    >
                        {{ filter.name }}
                    </a>
                </li>
            </ul>
        </div>

        <div v-if="appConfig.user" class="product-filter">
            <div class="product-filter-name">Terpene</div>
            <ul class="product-filter-items">
                <li v-for="terpene of terpenes" :key="terpene">
                    <a :class="{ active: filterStore.terpene === terpene }" @click.prevent="selectTerpene(terpene)">
                        {{ terpene }}
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>
