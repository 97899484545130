<script setup lang="ts">
import { assetLink } from "@/common/util"
import { useShopAppConfig } from "@/shop/composables"
import { useCartStore } from "@/shop/store/useCartStore"
import { $articleUnit } from "@/shop/util"
import { $currency } from "@/vf"
import PriceSpinner from "./PriceSpinner.vue"
/*─────────────────────────────────────┐
│   props                              │
└─────────────────────────────────────*/
withDefaults(
    defineProps<{
        error?: { errorType?: string; articles?: string[] }
        isCart?: boolean
    }>(),
    {
        error: undefined,
        isCart: false,
    },
)
/*─────────────────────────────────────┐
│   composables                        │
└─────────────────────────────────────*/
const cartStore = useCartStore()

const { appConfig } = useShopAppConfig()
</script>

<template>
    <div v-if="!cartStore.items.length" class="py-5 text-center text-muted">
        Du hast noch keine Artikel in Deinem Warenkorb
    </div>

    <template v-for="item in cartStore.items" :key="item">
        <div class="row checkout-cart-item align-items-center my-5">
            <div class="col-md-2 d-none d-md-block">
                <div class="pr-4">
                    <img v-if="!item.article.imageName" src="@/shop/assets/blossom.png" class="img-fluid" />
                    <img
                        v-if="item.article.imageName"
                        class="img-fluid"
                        :src="assetLink('/uploads/media/' + item.article.imageName)"
                    />
                </div>
            </div>
            <div class="col-10 col-md-5">
                <div class="checkout-cart-item-brand">
                    {{ item.article.publicNamePrimary }}
                </div>
                <div class="checkout-cart-item-name">{{ item.article.publicNameSecondary }}</div>
                <div
                    v-if="item.article.type === 'essence' || item.article.type === 'cannabis'"
                    class="checkout-cart-item-info"
                >
                    THC {{ item.article.thc }} % &middot; CBD {{ item.article.cbd }} %
                </div>

                <div
                    v-if="
                        item?.article?.id &&
                        error?.errorType == 'outOfStock' &&
                        error?.articles?.includes(item.article.id)
                    "
                    class="alert alert-danger"
                >
                    Dieses Produkt ist nicht oder nicht in ausreichender Menge auf Lager.
                </div>

                <div v-if="item.stockStatus === 'batch-overflow'" class="alert alert-info my-2">
                    <b>Achtung</b>
                    wir müssen diese Position auf zwei Chargen aufteilen. Es ist möglich, dass Teile Deiner Bestellung
                    andere Werte als die angegebenen haben werden.
                </div>

                <div v-if="item.stockStatus === 'not-enough-stock'" class="alert alert-warning my-2">
                    Wir haben die gewünschte Menge leider nicht auf Lager.
                </div>

                <div v-if="item.stockStatus === 'not-in-stock'" class="alert alert-warning" my-2>
                    Diese Sorte ist aktuell leider nicht lieferbar.
                </div>
            </div>

            <div class="col-12 col-md-5">
                <div class="mt-3 mt-md-0 d-flex justify-content-between align-items-lg-end flex-column flex-lg-row">
                    <div>
                        <div class>
                            <b>Menge</b>
                            <div class="text-muted mb-2">
                                <PriceSpinner
                                    :price="(item.singlePrice ?? 0) / (item.article.packageAmount ?? 1)"
                                ></PriceSpinner>
                                je {{ $articleUnit(item.article.unit!) }}
                            </div>
                        </div>

                        <div class="d-flex align-items-center" style="gap: 0.25rem">
                            <button
                                v-if="isCart"
                                class="btn btn-primary btn-sm py-2 px-4"
                                :disabled="cartStore.isMinimumAmount(item.article)"
                                @click="cartStore.changeAmount(item.article, item.amount - 1)"
                            >
                                -
                            </button>
                            <span class="d-inline-block text-center" style="min-width: 80px">
                                {{ item.amount }}
                                {{ item.article.packageAmount !== 1 ? "x " + item.article.packageAmount : "" }}
                                {{ item.article.unit }}
                            </span>
                            <button
                                v-if="isCart"
                                class="btn btn-primary btn-sm py-2 px-4"
                                @click="cartStore.changeAmount(item.article, item.amount + 1)"
                            >
                                +
                            </button>
                            <button
                                v-if="isCart"
                                class="btn btn-danger btn-sm py-2 px-4"
                                @click="cartStore.removeFromCart(item.article)"
                            >
                                <i class="fa fa-fw fa-trash"></i>
                            </button>
                        </div>
                    </div>
                    <div class="text-lg-right mt-3 mt-lg-0">
                        <div class="article-price">
                            <PriceSpinner :price="item.totalPrice"></PriceSpinner>
                        </div>
                        <div v-if="item.stockStatus === 'not-in-stock'">
                            <div
                                style="width: 1rem; height: 1rem; border-radius: 50%"
                                class="bg-danger d-inline-block"
                            ></div>
                            Nicht Lieferbar
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr class="my-2" />
    </template>

    <table class="table table-borderless my-3">
        <template v-if="true || appConfig.showPrices || !isCart">
            <template v-for="(item, $index) in cartStore.serverItems" :key="$index">
                <tr v-if="true || item.type !== 'fee' || !isCart">
                    <td colspan="4" class="text-right">
                        {{ item.name }}
                    </td>
                    <td class="text-right">
                        {{ $currency(item.totalPrice) }}
                    </td>
                </tr>
            </template>
            <template v-if="(cartStore.totalTax ?? 0) > 0">
                <tr>
                    <td colspan="4" class="text-right">Gesamtsumme ohne MwSt.</td>
                    <td class="text-right">
                        <PriceSpinner
                            :price="
                                cartStore.totalPrice === undefined
                                    ? undefined
                                    : cartStore.totalPrice - (cartStore.totalTax ?? 0)
                            "
                        ></PriceSpinner>
                    </td>
                </tr>
                <tr v-for="item in cartStore.totalTaxes" :key="item.vat">
                    <td colspan="4" class="text-right">zzgl. {{ item.vat }}% MwSt.</td>
                    <td class="text-right">
                        <PriceSpinner :price="cartStore.totalPrice === undefined ? undefined : item.tax"></PriceSpinner>
                    </td>
                </tr>
            </template>
            <tr>
                <td colspan="5"></td>
            </tr>
            <tr v-if="cartStore.totalPaymentPrice !== cartStore.totalPrice">
                <td colspan="4" class="text-right">Zwischensumme</td>
                <td class="text-right" style="min-width: 30%">
                    <PriceSpinner :price="cartStore.totalPrice"></PriceSpinner>
                </td>
            </tr>
            <tr v-if="cartStore.usedBalance !== 0" class="text-primary">
                <td colspan="4" class="text-right">Verwendetes Guthaben</td>
                <td class="text-right">
                    {{ $currency(cartStore.usedBalance) }}
                </td>
            </tr>
            <tr class="font-weight-bold">
                <td colspan="4" class="text-right">Gesamtsumme</td>
                <td class="text-right" style="min-width: 30%">
                    <PriceSpinner :price="cartStore.totalPaymentPrice"></PriceSpinner>
                </td>
            </tr>
            <tr v-if="isCart">
                <td colspan="5" class="text-right">ggf. zzgl. Versandkosten</td>
            </tr>
        </template>
        <template v-if="appConfig.user.payType === 'public_insurance' && isCart && cartStore.containsPrescriptionItems">
            <tr>
                <td colspan="5" class="text-right">Kosten: {{ $currency(1000) }} Gebühr pro Rezept.</td>
            </tr>
        </template>
    </table>
</template>
