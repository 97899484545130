import type { Article } from "@/model/backend/article"
import { useHttpClient } from "@/vf"
import type { AxiosResponse } from "axios"
import { defineStore } from "pinia"
import { computed, reactive, ref, watch } from "vue"
import { useShopAppConfig, type ShopAppConfig } from "../composables"

type StockStatus = "not-in-stock" | "not-enough-stock" | "batch-overflow" | "ok"

interface CartItem {
    article: Article
    amount: number
    singlePrice?: number
    totalPrice?: number
    tax?: number
    /** deprecated use stockStatus instead */
    // inStock?: boolean
    /** Can be undefined for special cart items (fees, shipping costs etc.) */
    stockStatus: StockStatus | undefined
}

interface ServerItem {
    id: string // article id
    name: string
    amount: number
    singlePrice?: number
    totalPrice?: number
    type: "cannabis" | "standard" | "merch" | "essence" | "fee" | "shipping" | "discount" | "balance" | "custom"
    packageAmount: number
    tax: number
    stockStatus: StockStatus | undefined
}

interface CartUpdateResponse {
    totalPrice: number
    totalPaymentPrice: number
    totalItems: number
    totalTax: number
    totalTaxes: { tax: number; vat: 7 | 19 | number }[]
    totalCannabisAmount: number
    usedBalance: number
    items: ServerItem[]
}

export interface CartUpload {
    file: Blob
    url: string | undefined
    selectedAt: Date
}

const STORAGE_KEY = "cann24-cart"

export const useCartStore = defineStore("cart", () => {
    const { post } = useHttpClient()
    const { appConfig } = useShopAppConfig()
    const items = reactive<CartItem[]>([])
    const uploads = reactive<CartUpload[]>([])

    const containsPrescriptionItems = computed(() => items.some(i => i.article.requiresPrescription))
    const totalItems = computed(() => items.reduce((n, i) => n + i.amount, 0))

    const totalTaxes = ref<CartUpdateResponse["totalTaxes"]>()
    const totalTax = ref<number>()
    const totalCannabisAmount = ref<number>(0)
    const usedBalance = ref<number>()
    const serverItems = reactive<ServerItem[]>([])
    const paymentMethod = ref<string>()

    const totalPrice = ref<number>()
    const totalPaymentPrice = ref<number>()

    const shippingMethod = ref<string>()
    const shippingOptions = reactive<Record<string, string[]>>({})
    const prescriptionTransmission = ref<string>()

    const requestCounter = ref(0)

    const shippingAddress = reactive({
        firstName: "",
        lastName: "",
        street: "",
        city: "",
        zip: "",
    })

    function _getItemFromArticle(article: Article, raiseErrorWhenNotFound = true): CartItem | undefined {
        const item = items.find(i => i.article.id == article.id)
        if (!item && raiseErrorWhenNotFound) {
            throw Error("article not found")
        }

        return item
    }

    watch(appConfig, () => _loadFromLocalStorage(appConfig.value))
    _loadFromLocalStorage(appConfig.value)

    function _loadFromLocalStorage(config: ShopAppConfig) {
        if (config.offline) return
        if (config.user) {
            shippingAddress.firstName = config.user.firstName ?? ""
            shippingAddress.lastName = config.user.lastName ?? ""
            shippingAddress.street = config.user.street ?? ""
            shippingAddress.city = config.user.city ?? ""
            shippingAddress.zip = config.user.zip ?? ""
        }
        for (const method of config.shippingMethods) {
            shippingOptions[method.name] = []
        }
        // reload cart from storage
        let data
        try {
            data = localStorage.getItem(STORAGE_KEY)
        } catch (e) {
            // security exception. ignore
            return
        }
        if (data) {
            items.splice(0, items.length)

            for (const item of JSON.parse(data)) {
                const article = config.articles.find(i => i.id == item.id)

                if (article) {
                    // for the moment: ignore stored special amounts
                    addToCart(article, item.amount || 1, false)
                }
            }
            update().catch()
        }
    }

    function removeFromCart(article: Article): void {
        const item = _getItemFromArticle(article)

        if (!item) {
            return
        }

        items.splice(items.indexOf(item), 1)
        update().catch()
    }

    function addToCart(article: Article, amount: number, updateState = true): void {
        const item = _getItemFromArticle(article, false)

        if (item) {
            item.amount += amount
        } else {
            items.push({
                article,
                amount: amount,
                singlePrice: undefined,
                totalPrice: undefined,
                stockStatus: undefined,
            })
        }

        if (updateState) {
            update().catch()
        }
    }

    function containsArticle(article: Article): boolean {
        return getCartItemForArticle(article) !== undefined
    }

    function getCartItemForArticle(article: Article): CartItem | undefined {
        return items.find(i => i.article.id == article.id)
    }

    function isMinimumAmount(article: Article) {
        const item = _getItemFromArticle(article, false)
        const minimum = article.type === "cannabis" ? 5 : 1

        return item?.amount === minimum
    }

    function changeAmount(article: Article, newAmount: number): void {
        const item = _getItemFromArticle(article)
        // const minimum = article.type === "cannabis" ? 5 : 1
        // if (newAmount < minimum) {
        //     removeFromCart(article)
        //     return
        // }

        if (!item) {
            return
        }

        item.amount = Math.max(0, newAmount)
        update().catch()
    }

    async function clear() {
        items.splice(0, items.length)
        uploads.splice(0, uploads.length)
        await update().catch()
    }

    async function update(params: { validatePendingOrders?: boolean } = {}): Promise<void> {
        if (!appConfig.value.user) {
            return
        }

        try {
            localStorage.setItem(
                STORAGE_KEY,
                JSON.stringify(
                    items.map(i => {
                        return {
                            id: i.article.id,
                            amount: i.amount,
                        }
                    }),
                ),
            )
        } catch (e) {
            // security exception. ignore
        }

        // the server side uses normalized order items. split the original/special double item into separate ones
        const postArticles: { articleId: string; amount: number; packageAmount: number }[] = []

        for (const item of items) {
            item.singlePrice = undefined
            item.totalPrice = undefined
            item.stockStatus = "ok" // hide all warnings

            totalPrice.value = undefined
            totalPaymentPrice.value = undefined

            if (item.amount > 0 && (item.article.packageAmount ?? 0) > 0) {
                postArticles.push({
                    articleId: item.article.id!,
                    amount: item.amount,
                    packageAmount: item.article.packageAmount ?? 0,
                })
            }
        }

        const sm = _getShippingMethodWithOptionsString()
        const currentRequest = ++requestCounter.value
        return post<CartUpdateResponse>("cart/calculate", {
            items: postArticles,
            shippingMethod: sm,
            ...params,
        }).then(r => {
            if (currentRequest !== requestCounter.value) {
                // we did not perform a new request since we started this one. use the result
                return
            }

            // this.totalItems = r.content.totalItems
            totalPrice.value = r.data.totalPrice
            totalPaymentPrice.value = r.data.totalPaymentPrice
            totalTaxes.value = r.data.totalTaxes
            totalTax.value = r.data.totalTax
            totalCannabisAmount.value = r.data.totalCannabisAmount
            usedBalance.value = r.data.usedBalance
            serverItems.splice(0, serverItems.length)
            for (const responseItem of r.data.items) {
                if (responseItem.type == "fee" || responseItem.type == "discount" || responseItem.type == "shipping") {
                    serverItems.push(responseItem)
                } else {
                    const cartItem = items.find(i => i.article.id == responseItem.id)
                    if (!cartItem) continue
                    if (responseItem.packageAmount == cartItem.article.packageAmount) {
                        cartItem.singlePrice = responseItem.singlePrice
                        cartItem.totalPrice = responseItem.totalPrice
                    } else {
                        throw new Error("response from server does not match our local package amounts")
                    }
                    cartItem.tax = responseItem.tax
                    // cartItem.inStock = responseItem.inStock
                    cartItem.stockStatus = responseItem.stockStatus
                }
            }
        })
    }

    function _getShippingMethodWithOptionsString() {
        let sm = shippingMethod.value ?? ""
        if (shippingOptions[sm] && shippingOptions[sm].length > 0) {
            sm += "+" + shippingOptions[sm].join("+")
        }
        return sm
    }

    function submitOrder(): Promise<
        AxiosResponse<{
            url: string
            status: string
            orderId: string
        }>
    > {
        const form = new FormData() // form for file upload
        let i = 0
        for (const item of items) {
            if (item.amount > 0 && item.article.id) {
                form.append("items[" + i + "][articleId]", item.article.id)
                form.append("items[" + i + "][amount]", item.amount.toString())
                form.append("items[" + i + "][packageAmount]", item.article?.packageAmount?.toString() ?? "1")
                i++
            }
        }
        i = 0
        for (const upload of uploads) {
            form.append("upload[" + i + "]", upload.file)
        }

        form.append("paymentMethod", paymentMethod.value ?? "")

        const sm = _getShippingMethodWithOptionsString()

        form.append("shippingMethod", sm)
        for (const [key, value] of Object.entries(shippingAddress)) {
            form.append("shippingAddress[" + key + "]", value)
        }

        if (prescriptionTransmission.value) {
            form.append("prescriptionTransmission", prescriptionTransmission.value)
        }

        return post("checkout/order", form)
    }

    return {
        items,
        uploads,
        addToCart,
        containsArticle,
        getCartItemForArticle,
        isMinimumAmount,
        changeAmount,
        clear,
        containsPrescriptionItems,
        totalItems,
        totalTaxes,
        totalTax,
        totalCannabisAmount,
        usedBalance,
        serverItems,
        totalPrice,
        totalPaymentPrice,
        shippingMethod,
        shippingOptions,
        shippingAddress,
        update,
        removeFromCart,
        paymentMethod,
        submitOrder,
        prescriptionTransmission,
    }
})
