export const terpenes = [
    "\u03b1-Cedren",
    "\u03b1-Guaien",
    "\u03b1-Humulen",
    "\u03b1-Phellandren",
    "\u03b1-Pinen",
    "Azulen",
    "\u03b2-Caryophyllen",
    "\u03b2-Myrcen",
    "\u03b2-Pinen",
    "Bisabolol",
    "Borneol",
    "Camphen",
    "Caryophyllenoxid",
    "Cymen",
    "Farnesen",
    "Geraniol",
    "Germacren",
    "Guaiol",
    "Limonen",
    "Linalool",
    "Nerolidol",
    "Ocimen",
    "Sabinen",
    "Terpinen",
    "Terpinolen",
    "Valencen"
]
