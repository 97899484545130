import type { Order } from "@/model/backend/order"

export function $uploadType(value: string | undefined) {
    switch (value) {
        case "prescription":
            return "Rezept"
        case "card":
            return "Krankenkassenkarte"
        case "id":
            return "Personalausweis"
        case "card_front":
            return "Krankenkassenkarte (Vorderseite)"
        case "card_back":
            return "Krankenkassenkarte (Rückseite)"
        case "id_front":
            return "Personalausweis (Vorderseite)"
        case "id_back":
            return "Personalausweis (Rückseite)"
        case "reimbursement_declaration":
            return "Kostenübernahmeerklärung"
        case "co_payment_exemption":
            return "Zuzahlungsbefreiung"
        case "other":
            return "Sonstiges"
    }

    return "Unbekannt"
}

export function $orderState(order: Order, detailed: boolean = false): string {
    const stateMap: Record<string, string> = {
        verification: "Offen",
        ready: "Bereit für Abfertigung",
        cancelled: "Abgebrochen",
        completed: "Abgeschlossen",
        shipped_not_paid: "Verschickt, Zahlung ausstehend",
        rejected: "Abgelehnt",
    }
    let status = stateMap[order.state ?? ""] ?? "Unbekannt"

    if (order.state === "verification" && detailed) {
        const blocker = []

        if (!order.paymentReceived) {
            blocker.push("Zahlung")
        }

        if (!order.reviewed) {
            blocker.push("Prüfung")
        }

        if (!order.prescriptionChecked && !order.isPrescriptionNotRequired) {
            blocker.push("Rezept")
        }
        if (blocker.length > 0) {
            status += " (" + blocker.join(", ") + ")"
        }
    }
    if (order.state === "ready" && detailed) {
        const blocker = []
        if (!order.paymentReceived) {
            blocker.push("Zahlung ausstehend")
        }
        if (blocker.length > 0) {
            status += " (" + blocker.join(", ") + ")"
        }
    }

    return status
}

export function $deliveryMethod(method: string | undefined) {
    switch (method) {
        case "pickup":
            return "Pick-Up"
        case "pickup_service":
            return "Abholung selber beauftragen"
        case "carrier":
            return "Lieferung durch Apothekenbote"
        case "dhl_express":
            return "DHL-Express"
        case "dhl_package":
            return "DHL-Paket"
        default: {
            if (!method) {
                return "-"
            }
            const x = method.split("+")
            x[0] = x[0].toLocaleUpperCase()
            return x.join("+")
        }
    }
}

export function $paymentProvider(provider: string | undefined) {
    switch (provider) {
        case "offline":
            return "Barzahlung"
        case "bankTransfer":
            return "Vorkasse"
        case "postPayment":
            return "Rechnung"
        default:
            return provider
    }
}

export function $prescriptionTransmission(id: string): string {
    const names: Record<string, string> = {
        "paper/letter": "Papier, per Brief",
        "paper/letter-doctor": "Papier, per Brief vom Arzt",
        "paper/in-person": "Papier, persönliche Abgabe",
        "digital/token": "E-Rezept, per Token",
        "digital/app": "E-Rezept, per App",
        "digital/kim": "E-Rezept, per KIM",
        "digital/health-card": "E-Rezept, per Gesundheitskarte",
        "email/link": "E-Mail, per Link",
        "email/encrypted": "E-Mail, verschlüsselt",
    }

    return names[id] ?? id
}

export function $payType(value: string) {
    switch (value) {
        case "public_insurance":
            return "Kassenpatient"
        case "public_free":
            return "Kassenpatient (Zuzahlungsbefreit)"
        case "private_insurance":
            return "Privatversichert"
        case "self":
            return "Selbstzahler"
        case "doctor":
            return "Arzt"
    }

    return "Unbekannt"
}

export function $gender(value: string) {
    switch (value) {
        case "m":
            return "männlich"
        case "f":
            return "weiblich"
        case "d":
            return "divers"
        case null:
        case undefined:
            return "nicht gewählt"
        default:
            return value
    }
}

export function $colorCategory(price: number) {
    // CAN-365
    if (price < 700) {
        return "blue"
    }
    if (price < 900) {
        return "green"
    }
    if (price < 1100) {
        return "yellow"
    }
    return "purple"
}
export function $colorCategoryWord(price: number) {
    const color = $colorCategory(price)

    switch (color) {
        case "blue":
            return "Blue"
        case "green":
            return "Green"
        case "yellow":
            return "Yellow"
        case "purple":
            return "Purple"
    }
}
