import { defineStore } from "pinia"
import { ref } from "vue"

export interface ArticleThcFilter {
    name: string
    min: number
    max: number
}

export const useArticleFilterStore = defineStore("article-filter", () => {
    const brand = ref<string>()
    const type = ref<string>()
    const species = ref<string>()
    const search = ref<string>()
    const thc = ref<ArticleThcFilter>()
    const priceCategory = ref<string>()
    const terpene = ref<string>()

    return { brand, type, species, search, thc, priceCategory, terpene }
})
