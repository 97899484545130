<script setup lang="ts">
import { useShopAppConfig } from "@/shop/composables"
import { useOverlayStore } from "@/shop/store"

const { appConfig } = useShopAppConfig()
const overlayStore = useOverlayStore()

function close() {
    overlayStore.close()
}
</script>
<template>
    <div class="login overlay-transparent">
        <div class="login-yin login-full text-left" style="max-height: 80vh; overflow-y: auto">
            <div v-html="appConfig.customEnterShopDialog?.text"></div>
            <button type="button" class="btn btn-primary mt-5" @click="close()">Schließen</button>
        </div>
    </div>
</template>
